<template lang="pug">
  AppButtonWithIcon(
    icon="plus-circle"
    type="add-new"
    v-bind="$props"
    v-on="$listeners"
  )
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: "actions.add_new"
      },
      disabled: Boolean
    },

    components: {
      AppButtonWithIcon: () => import("@/components/elements/AppButton/WithIcon")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  ::v-deep
    .app-button
      &-content
        padding-right: 6px
        padding-left: 2px
        .icon
          color: $default-white
          font-size: 1.2rem
</style>
